import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';
import CallbackCta from '../components/CallbackCta';
import { translateString } from '../utils';

const SystemsPageTemplate = ({
  data: { datoCmsSystemsArchive },
  pageContext: { locale },
}) => {
  const { seoMetaTags, slugLocales, title, subtitle, modularBlocks } =
    datoCmsSystemsArchive;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsSystemsArchive}
      verticalText={translateString('Systems', locale)}
    >
      <main>
        <Banner
          overline={translateString('Systems', locale)}
          heading={title}
          text={subtitle}
          locale={locale}
        />
        <ModularBlocks items={modularBlocks} locale={locale} />
      </main>
      <CallbackCta locale={locale} />
    </Layout>
  );
};

export const SystemsPageTemplateQuery = graphql`
  query SystemsPageTemplateQuery($id: String!) {
    datoCmsSystemsArchive(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      subtitle
      modularBlocks {
        ...PageImageCardsModularBlockFragment
      }
      ...LinkFragment
    }
  }
`;

export default SystemsPageTemplate;
